<template>
  <div class="home-app" v-show="isload">
    <loginheder @querycourse="querycourselist" />
    <div class="home" :class="icon_down?'':'half'" ref="home" @scroll="scroll">
      <div class="homeIndex">
        <div class="loaded-tip" v-if="queryThemesList.length==0">
          <img src="@/assets/login/datanone.png" alt="">
          <p>{{clazzObj.name?"当前班级没有课程内容，请联系客服人员":"你还没有分配班级，请联系我们工作人员"}}</p><p>服务热线：0731-85685008</p>
        </div>
        <div class="public" v-else>
          <div>
            <div class="item-box" v-for="(boxitem,index) in queryThemesList" :key="index">
              <div style="margin-top:80px;" v-if="index==0">
                <span class="shuxian"></span> <span class="title">{{clazzObj.name+"课程"}}</span>
                <div style="width: 6rem; display: inline-block; margin-left: 20px;  font-size: 16px;  color: rgb(200,200,200);">{{clazzObj.serviceDate?'服务期限：'+clazzObj.serviceDate.replaceAll('00:00:00',''):''}}</div>
              </div>
              <ul>
                  <li v-for="(modulitem) in boxitem.courseList" :key="modulitem.name" v-show="modulitem.periodCount!=0"
                  @click="modulClick( boxitem.seriesName,modulitem.name,modulitem.id,modulitem.descrption)">
                  <img class="item-img" :src="modulitem.image |urlHandler" />
                   <span class="length">共{{modulitem.periodCount}}节</span>
                  <div class="name">{{ modulitem.name }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="foote">
        <Loginfooter />
      </div>
      <!-- <div class="operation">
           <div @click="half"><img :class="icon_down?'icon_down':''" src="@/assets/homeOnline/icon_down.png" alt=""></div>
         <div @click="tuichu">  <img src="@/assets/homeOnline/icon_back.png" alt=""></div> 
       </div> -->
    </div>
  </div>
</template>

<script>
  import Loginfooter from '@/components/Loginfooter'
  import Loginheder from '@/components/loginHeder'

  export default {
    components: {
      Loginfooter,
      Loginheder
    },
    data() {
      return {
        navSelected: 0,
        scrollview: 0,
        userInfo: {},
        queryclazzlist: [], //班级列表
        clazzObj: {}, //当前班级
        queryThemesList: [], //视频数据列表
        navList: [],
        allNavData: [],
        isload:false
      }
    },
    activated() {
      this.$refs.home.scrollTop = this.scrollview
    },
    created(){
    
        
    },
    computed: {
      icon_down() {
        return this.$store.state.icon_down
      }
    },
    mounted() {

    },
    filters: {
      urlHandler: function (url) {
        if (!url) return
        let id = url.indexOf("http")
        if (id <= -1) return 'https://oss.eyyb.vip/' + url
        else return url
        // value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },

    methods: {
      // 页面滚动
      scroll() {
        this.scrollview = this.$refs.home.scrollTop
      },
      // 一半
      half() {
        this.$store.commit('icon_down', !this.icon_down)
      },

      // 详情
      modulClick(courseName, name, id,descrption) {
          localStorage.setItem('studyinfo',JSON.stringify({
            model: courseName,
            name: name,
            cid: id,
            path:'/home',
            descrption:descrption
          }))
        this.$router.push({path: '/study'})
      },
      //  获取视频列表
      querycourselist(item) {
        if(!this.$store.state.clazzObj.id){ this.isload=true; return;}
       this.axiosGet('/afterclass-api/api/yyb/v1/after/api/querycourselist', {
          clazzId: this.$store.state.clazzObj.id
        }, (res => {
          let array = JSON.parse(JSON.stringify(res.obj))
          array.unshift({
            seriesId: "0000000",
            seriesName: "全部"
          })
          if(JSON.stringify(this.allNavData)==JSON.stringify(res.obj)){
            console.log('querycourselist,数据未变动');
          }else{
            this.navList = array
            this.allNavData = res.obj
            this.queryThemesList = res.obj
          }
          
          var a=localStorage.getItem('clazzObj');
          if(a){
            this.clazzObj=JSON.parse(localStorage.getItem('clazzObj'))
          }
          this.isload=true;
        }))
      },


    }
  }
</script>


<style lang="less" scoped>
  .home-app {
    background: #ebf0f5;

  }

  .home {
    transition: all .8s;
  }

  .half {
    height: 50vh;
    margin-top: 50vh;
  }

  .operation {
    .icon_down {
      transform: rotate(-180deg);

    }

    position: fixed;
    bottom: 1.39rem;
    display: flex;
    flex-direction: column;
    left: 1.5rem;

    >div {
      margin: auto;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0735a4;
      border-radius: 50%;
      margin: 10px;

      >img {
        transition: all .5s;
        width: 34px;
        height: 34px;
        cursor: pointer;
      }
    }

  }

  .home {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .shuxian {
    width: 6px;
    height: 18px;
    display: inline-block;
    background: #0735a4;
    border-radius: 2px;
  }

  // .homeIndex {
  //       width: 1140px;
  //   background: #ebf0f5;
  //   position: relative;
  //   flex: 1;
  //       padding: 0 30px;
  //   // top: .8rem;
  // }
  

  .islogin {
    height: .8rem;

    .logopng {
      width: .48rem;
      height: .48rem;
    }

    .public {
      width: 11.65rem;
      margin: auto;
    }

    display: flex;
    width: 12rem;
    margin: auto;
    justify-content: space-between;

    >div {
      display: flex;
      align-items: center;


      width: 12rem;
      margin: auto;

      >div {
        display: flex;
        align-items: center;
      }

      .title {
        margin-left: 10px;
        font-size: .26rem;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        color: #0e1432;
      }

      .title1 {
        font-size: .26rem;
        color: #0e1432;
        font-weight: 400;
      }
    }

    .shuxian {
      margin: 0 .1rem;
    }

    .el-dropdown-link {
      color: #0735a4;
      cursor: pointer;
      margin-right: .2rem;
    }

    .primarybtn {
      text-align: center;
      margin-left: .2rem;
      padding: 0 .2rem;
      height: .3rem;
      line-height: .3rem;
      background: #e6ebf6;
      border-radius: .28rem;
      font-size: 12px;
      text-align: center;
      color: #0735a4;
      cursor: pointer;
    }
  }

  .homeIndex {

    width: 11.4rem;
    background: #fff;
    margin: 0 auto;
    padding: 0 30px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .loaded-tip {
      min-height: 8.6rem;
      text-align: center;
      font-size: 20px;
      p{
        text-align: center;
        height: 40px;
      }
      img{
        width: 90px;
        height: 109px;
        margin-top:2.5rem;
      }
    }
  }

  .count-icon {
    float: left;
    width: 16px;
    height: 16px;
    margin-top: 7px;
    margin-right: 7px;
    vertical-align: middle;
  }

  .item-box ul li div {

    padding-left: 10px;
    font-size: 14px;
    line-height: 30px;
    color: #999;
    margin: 10px -5px;
  }

  .item-box ul li .name {
    padding-left: 10px;
    margin-top: 10px;
    font-family: 'Microsoft YaHei', SimSun, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .item-box ul li .length {
    position: absolute;
    bottom: 0.7rem;
    right: 0.10rem;
    background-color: rgba(102, 102, 102, 1);
    color: #fff;
    text-align: center;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    font-size: 12px;
  }

  .item-box ul li .length:hover {
    background-color: rgba(102, 102, 102, 0.8);
  }

  .item-box ul {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.20rem;
    margin-left: -0.20rem;
  }

  .item-box ul li:hover {

    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
    transform: scale(1.05)
  }

  .item-box ul li {
    position: relative;
    transition: all .5s;
    border-radius: 8px;
    background: #fff;
    display: block;
    float: left;
    width: 3.52rem;
    height: 2.6rem;
    margin: .20rem;
    box-sizing: border-box;
    list-style: none;
    cursor: pointer;
    box-shadow: #bcbcbc 0 0 15px -1px;
    -webkit-box-shadow: #bcbcbc 0 0 15px -1px;
    -moz-box-shadow: #bcbcbc 0 0 15px -1px;
    -ms-box-shadow: #bcbcbc 0 0 15px -1px;
    -o-box-shadow: #bcbcbc 0 0 15px -1px;

  }

  .item-box ul li .item-img {
    width: 3.52rem;
    height: 2rem;
    border-radius: 8px;
  }

  .item-box {
    width: 11.45rem;
    height: auto;


    margin: auto;

    box-sizing: content-box;
  }

  .item-box .tag {

    padding: 0 10px;
    border: 1px solid #999;
    border-radius: 3px;
    line-height: 20px;
    height: 20px;
    margin: 0 5px;

  }


  .item-box .more:after {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #2d90f6;
    border-right: 1px solid #2d90f6;
    content: '';
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .item-box .more {
    float: right;
    padding-right: 58px;
    margin-top: 20px;
    font-size: 14px;
    color: #2d90f6;
    cursor: pointer;
  }

  .item-box .title-img {
    float: left;
    width: 15px;
    height: 13px;
    margin-top: 20px;
    margin-right: 5px;
  }

  .item-box .title {
    padding-left: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    color: #333;
  }

  .item-nav ul li:hover {
    background-color: #ededed;
  }

  .item-nav ul .this:hover {
    background-color: #2d90f6;
  }

  .item-nav {
    width: 11.65rem;
    height: 50px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 35px;
    margin: auto;
    background-color: #fff;
    box-sizing: content-box;

    >ul {
      width: 11.67rem;
      height: auto;
      margin: auto;
      overflow: hidden;

      >li {
        float: left;
        padding: 0 20px;
        margin: 0 10px;
        font-size: 14px;
        line-height: 35px;
        color: #2d90f6;
        cursor: pointer;
        border: 1px solid #2d90f6;
        border-radius: 3px;
      }

      .this {
        color: #fff;
        background-color: #2d90f6;
      }
    }
  }

  .teaching {
    width: 100%;

    padding: 30px 0 25px;
    background: #ebf0f5;
  }

  .foote {
    width: 100%;
    margin-top: 20px;
    background: #fff;
  }
</style>